<template>
  <Form
    ref="form"
    :submit="handleSubmit"
    :initialValues="initialValues"
  >
    <template v-slot="props">
      <div class="form-narrow">
        <div class="form-row">
          <TextField v-if="!initialValues.name" name="id" label="id*" :validate="composeValidators(required, uuid)" />
          <div v-else class="form-col">
            <label class="label">id</label>
            <div>{{initialValues.id}}</div>
          </div>
        </div>
        <div class="form-row">
          <TextField name="name" label="name*" :validate="required"/>
        </div>
        <div class="form-row">
          <div class="form-col">
            <SelectInput
              name="customerId"
              label="customer*"
              :options="customers"
              :validate="required"
            />
          </div>
          <div class="form-col">
            <SelectInput
              :disabled="!props.values.customerId"
              name="communityId"
              label="community*"
              :options="communities"
              :validate="required"
            />
          </div>
        </div>
        <ModalFooter :footer="footer" :tertiary="cancel" />
      </div>
    </template>
  </Form>
</template>

<script>
  import ModalNavigation from "@/mixins/ModalNavigation";
  import ValidatorMixin from "@/components/form/ValidatorMixin";
  import NotifyMixin from "@/mixins/NotifyMixin";
  import Form from "@/components/form/Form";
  import ModalFooter from "@/components/ui/modals/ModalFooter";
  import SelectInput from "@/components/form/SelectInput";
  import TextField from "@/components/form/TextField";

  export default {
    name: "LocationForm",
    components: {
      SelectInput,
      ModalFooter,
      Form,
      TextField,
    },
    mixins: [
      ModalNavigation,
      ValidatorMixin,
      NotifyMixin
    ],
    props: {
      onSubmit: {
        type: Function,
        required: true,
      },
      initialValues: {
        type: Object,
        default: () => ({})
      },
      loading: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    data() {
      return {
        customers: [],
        communities: [],
        footer: {
          primaryButton: 'save',
          tertiaryButton: 'cancel'
        },
      };
    },
    mounted() {
      this.$authDataProvider.getList('customers', {size: 999})
        .then(({content}) => {
          this.customers = content.filter(({deletedAt}) => !deletedAt)
            .map(({customerId: key, name: value}) => ({key, value}));
        })
        .catch((err) => this.notifyError(err.message));

      this.$watch(
        () => this.$refs.form?.formState.values.customerId,
        customerId => {
          if (customerId) {
            this.$authDataProvider.getList('customerCommunities', {size: 999, customerId})
              .then(({content}) => {
                this.communities = content.filter(({deletedAt}) => !deletedAt).map(({id: key, name: value}) => ({
                  key,
                  value
                }));

                if (!this.communities.find(c => c.key === this.$refs.form?.formState.values.communityId)) {
                  this.$refs.form.formApi.change('communityId', undefined);
                }
              })
              .catch(error => this.notifyError(error.message));
          }
        }, {
            immediate: true,
          }
      )
    },
    methods: {
      handleSubmit(values) {
        this.onSubmit({
          ...values,
          communityName: this.communities.find(c => c.key === values.communityId)['value'],
          customerName: this.customers.find(c => c.key === values.customerId)['value'],
        })
      },
      cancel(e) {
        e.preventDefault();
        this.close();
      },
    }
  }
</script>

<style scoped>

</style>
